<template>
  <div class="white text-body-2 black--text mt-5 mb-5 pt-6 pb-6 pl-8 pr-8 d-flex align-center rounded-lg">
    <div class="info--usuario font-weight-bold info--number text-truncate">
      {{ user.username }}
    </div>
    <div class="info--rol d-flex justify-end">
      <v-select class="mt-4" :items="items" label="Elige un rol" outlined v-model="user.rol"></v-select>
    </div>

    <div class="info--action d-flex justify-center">
      <v-btn class="mr-2" depressed outlined color="primary" @click="$emit('updateRole', user)">ACTUALIZAR ROL</v-btn>
    </div>
  </div>
</template>

<script>
// Bus

export default {
  name: "RolesTableItem",
  props: {
    user: {
      type: Object,
      required: true
    }
  },
  data: () => ({
    showDelete: false,
    showEdit: false,
    items: ['admin', 'visualizador']
  }),
};
</script>


<style lang="scss" scoped>
.info--usuario {
  width: 30%;
}

.info--rol {
  width: 30%;
}

.info--action {
  width: 40%;
}</style>